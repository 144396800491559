import { PaginatedBaseResponse, Money, buildRequestQuery, BaseFilterRequest, BaseResponse, BaseExchange } from '~/sdk/shared'
import Axios, { AxiosResponse } from 'axios'
import API from '~/sdk/client'

export type TaxReductionDetails = {
  amount: number,
  type: 'ROT' | 'RUT',
}

export enum InvoiceStatus {
  Sold = 'SOLD',
  PartlyPaid = 'PARTLY_PAID',
  Paid = 'PAID',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Postponed = 'POSTPONED',
  Secured = 'SECURED',
  Contested = 'ISSUE_CONTESTED',
  PaidToSeller = 'ISSUE_PAID_TO_SELLER',
  Repurchased = 'ISSUE_REPURCHASED',
  Credited = 'ISSUE_CREDITED',
  ReceiverNotCreditWorthy = 'ISSUE_RECEIVER_NOT_CREDITWORTHY',
  Sellable = 'SELLABLE',
  Repurchase = 'REPURCHASE',
  Securable = 'SECURABLE',
  Overdue = 'OVERDUE',
  IssueInvalidInvoice = 'ISSUE_INVALID_INVOICE',
  IssueInvalidCurrency = 'ISSUE_INVALID_CURRENCY',
  IssueAmountTooSmall = 'ISSUE_AMOUNT_TOO_SMALL',
  IssueCreditableAmountTooSmall = 'ISSUE_CREDITABLE_AMOUNT_TOO_SMALL',
  IssueInvoiceMissingInvoiceNr = 'ISSUE_INVOICE_MISSING_INVOICE_NR',
  IssueDueDateTooSoon = 'ISSUE_DUE_DATE_TOO_SOON',
  IssueDueDatePassed = 'ISSUE_DUE_DATE_PASSED',
  IssueAlreadyPaid = 'ISSUE_ALREADY_PAID',
  IssueInvoiceeMissingRegistrationNumber = 'ISSUE_INVOICEE_MISSING_REGISTRATION_NUMBER',
  IssueInvoiceeNotPublicCompany = 'ISSUE_INVOICEE_NOT_PUBLIC_COMPANY',
  IssueInvoiceeNotCreditableCompany = 'ISSUE_INVOICEE_NOT_CREDITABLE_COMPANY',
  IssueInvoiceeSameCompany = 'ISSUE_INVOICEE_SAME_COMPANY',
  IssueInvoiceeIsGroupCompany = 'ISSUE_INVOICEE_IS_GROUP_COMPANY',
  IssueInvoiceeHaveZeroOrBelowCreditScore = 'ISSUE_INVOICEE_HAVE_ZERO_OR_BELOW_CREDIT_SCORE',
  IssueInvoiceIsCredit = 'ISSUE_INVOICE_IS_CREDIT',
  IssueManuallyClosed = 'ISSUE_MANUALLY_CLOSED',
  IssueBillogramSold = 'ISSUE_BILLOGRAM_SOLD',
  IssueDateInFuture = 'ISSUE_DATE_IN_FUTURE',
  IssueInvoiceMoreThanHalfPaid = 'ISSUE_INVOICE_MORE_THAN_HALF_PAID',
  IssueDatePassed = 'ISSUE_DATE_PASSED',
  IssueInvoiceeMissingAddress = 'ISSUE_INVOICEE_MISSING_ADDRESS',
  IssueInvoiceSold = 'ISSUE_INVOICE_SOLD',
  IssueInvoiceVatDeduction = 'ISSUE_INVOICE_VAT_DEDUCTION',
  IssueInvoiceSellPriceZero = 'ISSUE_INVOICE_SELL_PRICE_ZERO',
  IssueInvoiceNotSellable = 'ISSUE_INVOICE_NOT_SELLABLE',
  IssueBuyerSoleTraderSellInvoiceAmountExceedsLimit = 'ISSUE_BUYER_SOLE_TRADER_SELL_INVOICE_AMOUNT_EXCEEDS_LIMIT',
}

export type Customer = {
  id: number,
  externalId: string,
  name: string,
  identityNumber: string,
}

export type Reference = {
  ourReference: string
  yourReference: string
  name:	string
  phone: string
  email: string
  address1: string
  address2:	string
  postalCode: string
  city: string
  countryCode: string
}

export enum InvoiceDeliveryMethod {
  Electronic = 'ELECTRONIC',
  Postal = 'POSTAL',
  Email = 'EMAIL'
}

export type Invoice = {
  id: number,
  invoiceNumber: string,
  ocr: string,
  amount: Money,
  amountPaid: Money,
  attachments: boolean,
  balance: Money,
  comment: false,
  creditableAmount: Money,
  originalCurrencyAmount: Money,
  customer: Customer,
  customerId: number,
  dueDate: string,
  issueDate: string,
  perceivedBalance: Money,
  pledgeText: true,
  repurchase: false,
  sellPrice: Money,
  sellingFee: Money,
  status: InvoiceStatus[],
  taxReductionDetails: TaxReductionDetails | null,
  createdAt: string,
  updateAt: string,
  invoiceDeliveryMethod: InvoiceDeliveryMethod,
  reference: Reference | null,
  paidOutAmount: Money,
  isEditable: boolean,
  vat: Money,
}

export interface InvoiceFilterQuery {
  buyerId?: string,
  search?: string,
  state?: string,
  dueDate?: string,
}

export enum InvoiceSortColumn {
  IssueDate = 'issueDate',
  DueDate = 'dueDate',
  Amount = 'amount',
}

interface CreateInvoiceRequest {
  foreignIdentityNumber: boolean
  customerId: number
  invoiceNumber: string
  amount: number
  currency: string
  vat: number
  issueDate: string
  dueDate: string
  reference?: {
    ourReference?: string
    yourReference?: string
    name?: string
    phone?: string
    email?: string
    address1?: string
    address2?: string
    postalCode?: string
    city?: string
    countryCode?: string
  }
}

interface UpdateInvoice {
  yourRef: string | null
  invoiceDeliveryMethod: InvoiceDeliveryMethod
}

export async function get(request?: BaseFilterRequest<InvoiceFilterQuery, InvoiceSortColumn>): Promise<AxiosResponse<PaginatedBaseResponse<Invoice>>> {
  return await API.get(`internal/v2/company/invoices${buildRequestQuery(request)}`)
}

export async function show(id: string): Promise<BaseResponse<Invoice>> {
  return await API.get(`internal/v2/company/invoices/${id}`)
}

export async function create(payload: BaseExchange<CreateInvoiceRequest>): Promise<BaseResponse<Invoice>> {
  return await API.post('internal/v2/company/invoices', payload)
}

export async function patch(id: string, payload: BaseExchange<Omit<CreateInvoiceRequest, 'foreignIdentityNumber'>>): Promise<BaseResponse<Invoice>> {
  return await API.patch(`internal/v2/company/invoices/${id}`, payload)
}

export async function put(id: string, payload: BaseExchange<UpdateInvoice>): Promise<BaseResponse<Invoice>> {
  return await API.put(`internal/v2/company/invoices/${id}`, payload)
}

export async function upload(file: File, invoiceId: number): Promise<void> {
  const lastDot = file.name.lastIndexOf('.');
  const extension = file.name.substring(lastDot + 1);

  const { data: signedUrlResponse } = await API.post<
    BaseExchange<{ extension: string }>,
    BaseExchange<{ data: { url: string, source: string }}>
  >(`internal/v2/company/invoices/${invoiceId}/attachments/upload`, {
    data: { extension }
  })

  await Axios.put(signedUrlResponse.data.url, file, {
    headers: { 'Content-Type': file.type }
  })

  await API.post<
    BaseExchange<{ source: string, filename: string }>
  >(`internal/v2/company/invoices/${invoiceId}/attachments`, {
    data: {
      source: signedUrlResponse.data.source,
      filename: file.name,
    }
  })
}


