import { SelectHTMLAttributes, forwardRef, Ref } from 'react'
import { HasClassName, ChevronDownIcon } from '@monto/react-common-ui'
import { FieldState } from '../Group/Group';

export interface SelectProps extends HasClassName, SelectHTMLAttributes<HTMLSelectElement> {
  value?: string
  readOnly?: boolean
  disabled?: boolean
  state?: FieldState,
  otherRef?: Ref<HTMLSelectElement>
  children: JSX.Element[]
}

const SelectElement = (props: SelectProps) => {
  const {
    otherRef,
    className,
    children,
    state,
    ...otherProps
  } = props

  return (
    <div className="input-wrapper select">
      <select
        ref={otherRef}
        style={{ backgroundImage: 'none' }}
        disabled={props.readOnly}
        data-state={state}
        {...otherProps}
      >
        {children}
      </select>

      {!props.readOnly &&
        <span><ChevronDownIcon /></span>
      }
    </div>
  )
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(props, ref) {
  return (
    <SelectElement
      otherRef={ref} {...props}
    />
  )
})
