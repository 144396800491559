import { BaseResponse, BaseExchange, EmptyBaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export type Entity = {
  id: number,
  identityNumber: number,
  phone: string,
  name: string,
  email: string,
  active: boolean
}

export type GuarantorCreatePayload = {
  ssn: string,
  email: string,
  phone?: string
}

export type GuarantorType = {
  guarantor: 'SELF' | 'OTHER'
}

export async function get(): Promise<BaseResponse<Entity[]>> {
  return await API.get('internal/v1/company/guarantors')
}

export async function show(id: number): Promise<BaseResponse<Entity>> {
  return await API.get(`internal/v1/company/guarantors/${id}`)
}

export async function post(payload: BaseExchange<GuarantorCreatePayload>): Promise<BaseResponse<Entity>> {
  return await API.post('internal/v1/company/guarantors', payload)
}

export async function update(id: number, payload: BaseExchange<Partial<GuarantorCreatePayload>>): Promise<BaseResponse<Entity>> {
  return await API.put(`internal/v1/company/guarantors/${id}`, payload)
}

export async function remove(id: number): Promise<EmptyBaseResponse> {
  return await API.delete(`internal/v1/company/guarantors/${id}`)
}

