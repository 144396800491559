import React from 'react'
import classNames from 'classnames'

export type LoaderColor = 'white' | 'primary'

export type LoaderProps = {
  color: LoaderColor
  className?: string,
  loading?: boolean
}

type DotProps = {
  color: LoaderColor
  className?: string
}

const Dot: React.FC<DotProps> = ({ color, className }) => (
  <div className={classNames(className, 'w-2 h-2 rounded-large animate-scale-125', { 'bg-white': color === 'white', 'bg-primary-400': color === 'primary' })}></div>
)

export const Loader: React.FC<LoaderProps> = ({ color, className, loading }) => {

  if (loading !== undefined && loading === false) return null;
  return (
    <div className={classNames('flex space-x-1', className)}>
      <Dot color={color} />
      <Dot color={color} className="animation-delay-200" />
      <Dot color={color} className="animation-delay-400" />
    </div>
  )
}
