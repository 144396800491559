import React from 'react'
import { HasClassName } from '@monto/react-common-ui'

export interface RadioProps extends HasClassName, React.InputHTMLAttributes<HTMLInputElement> {
  innerRef?: React.Ref<HTMLInputElement>
}

const RadioElement: React.FC<RadioProps> = ({ innerRef, className, ...rest }) => {
  return (
    <input
      className={`w-6 h-6 border-gray-400 outline-none active:border-primary-500 checked:text-primary-500 focus:ring-4 focus:ring-primary-50 ${className}`}
      ref={innerRef}
      type="radio"
      {...rest}
    />
  )
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(function radio(props, ref) {
  return (
    <RadioElement innerRef={ref} {...props} />
  )
})
