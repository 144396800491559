import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { get, post, update, remove } from 'sdk/internal/v1/company/guarantors';
import { show as fetchGuarantor } from 'sdk/internal/v1/company/guarantors';
import { Entity as GuarantorsEntity, GuarantorCreatePayload } from 'sdk/internal/v1/company/guarantors';
import { BaseResponse, BaseExchange } from '~/sdk/shared';
import { RootState } from '~/store';
import removeDuplicates from '~/utils/removeDuplicates';

const guarantorsAdapter = createEntityAdapter<GuarantorsEntity>({
  selectId: (guarantor) => guarantor.id,
});

export const guarantorsSelectors = guarantorsAdapter.getSelectors(
  (state: RootState) => state.guarantors
);

export const selectGuarantorBySSN = createSelector(
  [guarantorsSelectors.selectAll, (state: RootState, ssn: string) => ssn],
  (guarantors, ssn) => {
    return guarantors.find((guarantor) => guarantor.identityNumber.toString() === ssn);
  }
);

export const selectGuarantorById = createSelector([guarantorsSelectors.selectById], (guarantor) => guarantor);
export const selectAllGuarantors = createSelector([guarantorsSelectors.selectAll], (guarantor) => guarantor);

export type GuarantorsState = {
  entities: Record<string, GuarantorsEntity | undefined>;
  ids: (string | number)[];
  selectedGuarantor: GuarantorsEntity | null;
  loading: boolean;
  error: string | undefined;
};

const initialState: GuarantorsState = {
  ...guarantorsAdapter.getInitialState(),
  selectedGuarantor: null,
  loading: false,
  error: undefined,
};

export const fetchGuarantors = createAsyncThunk(
  'guarantors/fetchGuarantors',
  async () => {
    const response: BaseResponse<GuarantorsEntity[]> = await get();
    return removeDuplicates(response.data.data, 'identityNumber');
  }
);

export const postGuarantor = createAsyncThunk<
  GuarantorsEntity,
  GuarantorCreatePayload,
  { rejectValue: unknown; state: RootState }
>('guarantors/postGuarantors', async (payload: GuarantorCreatePayload, thunkAPI) => {

  // look for duplicate based on their id
  const existingGuarantor = selectGuarantorBySSN(
    thunkAPI.getState(),
    payload.ssn
  );

  if (existingGuarantor) {
    return thunkAPI.rejectWithValue({
      error: 'A guarantor with the same social security number already exists.',
    });
  }

  const baseExchangePayload: BaseExchange<GuarantorCreatePayload> = { data: payload };
  const response: BaseResponse<GuarantorsEntity> = await post(baseExchangePayload);
  return response.data.data;
});

export const fetchGuarantorById = createAsyncThunk(
  'guarantors/fetchIfNotInStore',
  async (id: number, { getState, dispatch }) => {
    const state: RootState = getState() as RootState;
    const existingGuarantor = selectGuarantorById(state, id);

    if (!existingGuarantor) {
      // Fetch the guarantor from the API if it doesn't exist in the state
      const response: BaseResponse<GuarantorsEntity> = await fetchGuarantor(id);
      return response.data.data;
    }
    return existingGuarantor;
  }
);

export const patchGuarantor = createAsyncThunk<
  GuarantorsEntity,
  { id: number; payload: Partial<GuarantorCreatePayload> },
  { rejectValue: unknown }
>('guarantors/patchGuarantor', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const baseExchangePayload: BaseExchange<Partial<GuarantorCreatePayload>> = { data: payload };
    const response: BaseResponse<GuarantorsEntity> = await update(id, baseExchangePayload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteGuarantor = createAsyncThunk(
  'guarantors/deleteGuarantor',
  async (id: number) => {
    await remove(id);
    return id;
  }
);

const guarantorsSlice = createSlice({
  name: 'guarantors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGuarantors.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchGuarantors.fulfilled, (state, action) => {
        guarantorsAdapter.setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(fetchGuarantors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchGuarantorById.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchGuarantorById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedGuarantor = action.payload;
      })
      .addCase(fetchGuarantorById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postGuarantor.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(postGuarantor.fulfilled, (state, action) => {
        guarantorsAdapter.addOne(state, action.payload);
        state.loading = false;
      })
      .addCase(postGuarantor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(patchGuarantor.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(patchGuarantor.fulfilled, (state, action) => {
        guarantorsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
        state.loading = false;
      })
      .addCase(patchGuarantor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteGuarantor.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(deleteGuarantor.fulfilled, (state, action) => {
        state.loading = false;
        guarantorsAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteGuarantor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default guarantorsSlice.reducer;
