import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch } from 'sdk/internal/v1/company/settings/bank-account';
import { Entity as BankAccountEntity } from 'sdk/internal/v1/company/settings/bank-account';
import { BaseResponse, BaseExchange } from '~/sdk/shared';
import { ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '~/store';


export type BankAccountState = {
  data: BankAccountEntity | null;
  loading: boolean;
  error: string | undefined;
};

const initialState: BankAccountState = {
  data: null,
  loading: false,
  error: undefined,
};

export const fetchBankAccount = createAsyncThunk(
  'bank-account/fetchBankAccount',
  async () => {
    const response: BaseResponse<BankAccountEntity> = await get();
    return response.data.data; // Access the actual data property inside the response data
  }
);

const patchBankAccountThunk = createAsyncThunk<
  BankAccountEntity, // FulFilled
  BankAccountEntity, // Argument of the payload creator
  { rejectValue: unknown } // Rejected
>('bankAccount/patchBankAccount', async (payload: BankAccountEntity) => {
  const baseExchangePayload: BaseExchange<BankAccountEntity> = {
    data: payload,
  };
  await patch(baseExchangePayload);
  return payload;
});

export const patchBankAccount = (
  fields: BankAccountEntity
): ThunkAction<Promise<BankAccountEntity | undefined>, RootState, unknown, any> => async (dispatch) => {
  const resultAction = await dispatch(patchBankAccountThunk(fields));
  const bankAccount = patchBankAccountThunk.fulfilled.match(resultAction) ? resultAction.payload : undefined;
  return bankAccount
};

const bankAccountSlice = createSlice({
  name: 'bankaccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccount.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchBankAccount.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchBankAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(patchBankAccountThunk.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(patchBankAccountThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(patchBankAccountThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default bankAccountSlice.reducer;
