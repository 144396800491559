
export function poll<T>(fn: () => Promise<T>, condition: (payload: T) => boolean, timeout: number, interval: number): Promise<T> {
  const timeoutEndTime = Number(new Date()) + timeout;

  const promise = (resolve: (payload: T) => void, reject: (error: Error) => void) => {

    fn()
      .then((payload) => {

        if (condition(payload)) {
          resolve(payload)
        } else if (Number(new Date()) < timeoutEndTime) {
          setTimeout(promise, interval, resolve, reject)
        } else {
          reject(
            new Error('[Poll] timed out')
          )
        }
      })
      .catch(e => reject(
        new Error(e)
      ))
  }

  return new Promise(promise)
}
