import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'
import * as SDK from '~/sdk'

export interface Request {
  target?: SDK.Internal.V1.Auth.BankId.TargetUnit
  personalMessage?: string
  guarantorIds: number[]
  repaymentTime: string
  amount: number
  ssn?: string
}

export interface Response {
  advancementId: string
  autoStartUrl: string
  sessionId: string
}

export async function post(payload: BaseExchange<Request>): Promise<BaseResponse<Response>> {
  return await API.post<BaseExchange<Request>, BaseResponse<Response>>('internal/v1/company/applications/business-loan', payload)
}

export * as Session from './session'
