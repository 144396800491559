import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'sdk/internal/v2/company/products/invoice-discounting';
import { Entity as InvoiceDiscountingEntity } from 'sdk/internal/v2/company/products/invoice-discounting';
import { BaseResponse } from '~/sdk/shared';
import { RequirementType } from 'sdk/internal/v2/company/products'


function getTypes(items: any = []): string[] {
  return items?.map((item: any) => item.type)
}

export type InvoiceDiscountingState = {
  data: InvoiceDiscountingEntity | null;
  loading: boolean;
  error: string | undefined;
  showCreditInfo: boolean;
};

const initialState: InvoiceDiscountingState = {
  data: null,
  loading: false,
  error: undefined,
  showCreditInfo: true
};

export const fetchInvoiceDiscounting = createAsyncThunk(
  'invoicediscounting/fetchInvoiceDiscounting',
  async () => {
    const response: BaseResponse<InvoiceDiscountingEntity> = await get();
    return response.data.data;
  }
);

const invoiceDiscountingSlice = createSlice({
  name: 'invoicediscounting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceDiscounting.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchInvoiceDiscounting.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;

        const requirements = getTypes(state.data?.requirements.filter((r) => r.type !== RequirementType.KYC));
        state.showCreditInfo = requirements.includes('CONNECT_ERP');
      })
      .addCase(fetchInvoiceDiscounting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default invoiceDiscountingSlice.reducer;
