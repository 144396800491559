import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'sdk/internal/v2/company/products/factoring';
import { Entity as FactoringEntity } from 'sdk/internal/v2/company/products/factoring';
import { BaseResponse } from '~/sdk/shared';

export type FactoringState = {
  data: FactoringEntity | null;
  loading: boolean;
  error: string | undefined;
};

const initialState: FactoringState = {
  data: null,
  loading: false,
  error: undefined,
};

export const fetchFactoring = createAsyncThunk(
  'factoring/fetchFactoring',
  async () => {
    const response: BaseResponse<FactoringEntity> = await get();
    return response.data.data;
  }
);

const factoringSlice = createSlice({
  name: 'factoring',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFactoring.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchFactoring.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchFactoring.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default factoringSlice.reducer;
