/**
 * Set, get and remove data in local storage
 *
 * @param key The storage identifier
 */
function useLocalStorage<T>(key: string) {

  return {
    get: () => {
      let raw;
      if (typeof window !== 'undefined') {
        raw = localStorage.getItem(key);
      }

      return raw ? JSON.parse(raw) : null;
    },
    set: (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    remove: () => {
      localStorage.removeItem(key);
    },
  };
}

export default useLocalStorage;
