import { BaseExchange, BaseResponse, EmptyBaseResponse } from '~/sdk/shared'
import { Entity as EntityV2 } from '~/sdk/internal/v2/company/kyc'
import API from  '~/sdk/client'

export type Status = {
  status: 'OUTDATED' | 'NEED_CHECKUP' | 'VALID' | 'CHECKED_UP' | 'REJECTED' | 'NO'
}

export type Entity = {
  uid: string
  identityNumber: number
  phone: string
  name: string
  email: string
  active: boolean
}

export interface PostPayload {
  industry: string
  financingIntention: 'CONTINUING_OPERATIONS' | 'CONTINUING_EXPANSION' | 'OTHER'
  financingIntentionOther: string | null
  expectedAmountFrom: number,
  expectedAmountTo: number | null,
  financingRecurrence: 'LT_MONTHLY' | 'MONTHLY' | 'ONCE' | 'UNKNOWN'
  otherDirectIndirectOwners: {
    ownershipPercentage: number
    identityNumber: string
  }[]
  beneficialOwners: EntityV2[] | string | null
  pepPerson: string | null,
  relatedPepPerson: string | null
}

export async function get(): Promise<BaseResponse<Status>> {
  return await API.get('internal/v1/company/kyc')
}

export async function post(payload: BaseExchange<PostPayload>): Promise<EmptyBaseResponse> {
  return await API.post('internal/v1/company/kyc', payload)
}
