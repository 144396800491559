// PhoneField.tsx
import { useState, Ref, forwardRef, useEffect, ChangeEvent, createRef, InputHTMLAttributes } from 'react'
import { parsePhoneNumber, PhoneNumberParseOptions, ParsedPhoneNumber, getExample } from 'awesome-phonenumber'
import { Input } from '../Input/Input'
import { masker, ReturnType } from '@monto/react-common-ui'
import { HasClassName } from '@monto/react-common-ui'
import { FieldState } from '../Group/Group';

export interface PhoneProps extends HasClassName, InputHTMLAttributes<HTMLInputElement> {
  locale: string
  name: string
  getPhoneNumber?: (phoneNumber: ParsedPhoneNumber) => void
  otherRef?: Ref<HTMLInputElement>
  readOnly?: boolean
  state?: FieldState
}

const PhoneElement = ({
  locale = 'se',
  name,
  getPhoneNumber,
  readOnly = false,
  otherRef,
  state,
  ...props
}: PhoneProps) => {

  if (locale === 'sv') locale = 'SE'
  if (locale === 'en') locale = 'GB'

  const [inputValue, setInputValue] = useState(props.value?.toString() || '')
  const inputRef = otherRef || createRef()
  const [isValid, setIsValid] = useState(true)
  const [regionCode, setRegionCode] = useState(locale.toUpperCase() || 'SE') // eg SE

  // always return the e164 type phone number
  const phoneMask = (): ReturnType => masker((unmasked) => {
    const masked = parsePhoneNumber(unmasked as string, { regionCode: regionCode }).number?.e164
    return masked || ''
  }, (masked) => {
    const unmasked = parsePhoneNumber(masked as string, { regionCode: regionCode }).number?.input
    return unmasked || ''
  })

  useEffect(() => {
    if (inputValue) {

      const phoneNumber = parsePhoneNumber(inputValue, { regionCode: locale } as PhoneNumberParseOptions)
      setIsValid(phoneNumber.valid)

      // run callback with formatted number
      if (getPhoneNumber) getPhoneNumber(phoneNumber)

      // set current region code.
      if (phoneNumber.regionCode) setRegionCode(phoneNumber.regionCode)
    } else {
      setIsValid(true)
    }
  }, [inputValue, locale, getPhoneNumber])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const phoneExample = (): string => {
    const example = getExample(regionCode)
    return example.number?.international || ''
  }

  return (
    <Input
      id={name}
      name={name}
      data-state={(state === 'error' || !isValid) ? 'error' : ''}
      placeholder={props.placeholder || phoneExample()}
      onKeyDown={phoneMask().onKeyDown}
      onChange={(event) => {
        phoneMask().onChange
        handleChange(event)
        return props.onChange
      }}
      onBlur={(event) => {
        handleChange(event)
        return props.onBlur
      }}
      onInput={handleChange}
      readOnly={readOnly}
      onFocus={props.onFocus}
      className="flex-1"
      ref={inputRef}
      type="tel"
      {...props}
    />
  )
}

export const Phone = forwardRef<HTMLInputElement, PhoneProps>(function Phone(props, ref) {
  return (
    <PhoneElement otherRef={ref} {...props}  />
  )
})
