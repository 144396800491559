import React, { createContext, useEffect, useState } from 'react'
import * as API from '~/sdk'
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { setCurrentCompanyId } from '~/store/application/applicationSlice'

export const LS_ORIGINAL_AUTH_KEY = '_auth.original_token'
export const LS_IMPERSONATE_KEY = '_auth.impersonate'
export const LS_AUTH_KEY = '_auth.token'

export type AuthUserContract = {
  id: number
  verifications: API.Internal.V1.User.Me.Verification[]
  permissions: API.Internal.V1.User.Me.Permission[]
  companies: API.Internal.V1.User.Me.Company[]
  firstName: string
  lastName: string
  email: string
  mailSetting: boolean
  socialSecurityNumber: string
  phone: string
  locale: string
}

export type AuthTokenContract = {
  accessToken: string
  tokenType: string
  expiresAt: string
}

export type AuthUserType = AuthUserContract | null | undefined

export type AuthContextContract = {
  setImpersonation: (token: AuthTokenContract) => void
  setToken: (token: AuthTokenContract) => void
  exitImpersonating: () => void
  authenticate: () => void
  impersonating: boolean
  user: AuthUserType
  logout: () => void
}

/* eslint "@typescript-eslint/no-empty-function": "off" */
export const AuthContext = createContext<AuthContextContract>({
  exitImpersonating: () => { },
  setImpersonation: () => { },
  authenticate: () => { },
  impersonating: false,
  setToken: () => { },
  logout: () => { },
  user: undefined,
})

async function fetchUser(): Promise<AuthUserContract> {
  const { data: response } = await API.Internal.V1.User.Me.get()

  return {
    id: response.data.id,
    verifications: response.data.verifications,
    permissions: response.data.permissions,
    companies: response.data.companies,
    firstName: response.data.firstName,
    lastName: response.data.lastName,
    email: response.data.email,
    mailSetting: response.data.mailSetting,
    socialSecurityNumber: response.data.socialSecurityNumber,
    phone: response.data.phone,
    locale: response.data.locale
  }
}

export const AuthProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [impersonating, setImpersonating] = useState<boolean>(false)
  const [user, setUser] = useState<AuthUserType>(undefined)

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hasToken() && user === undefined) {
      setImpersonating(isImpersonating())
      authenticate()
    } else {
      setUser(null)
    }
  }, [])

  /**
   * Method to sign the currently authenticated user out.
   */
  async function logout() {
    localStorage.removeItem(LS_IMPERSONATE_KEY)
    localStorage.removeItem(LS_AUTH_KEY)
    setUser(null)
  }

  /**
   * Method to store the token
   */
  function setToken(token: AuthTokenContract | null) {
    localStorage.setItem(LS_AUTH_KEY, JSON.stringify(token))
  }

  /**
   * Method to store the token as impersonator
   */
  function setImpersonation(token: AuthTokenContract | null) {
    const originalToken = localStorage.getItem(LS_AUTH_KEY)

    if (!originalToken) {
      throw new Error('Could not impersonate, since there is no previous token')
    }

    localStorage.setItem(LS_ORIGINAL_AUTH_KEY, originalToken)
    localStorage.setItem(LS_IMPERSONATE_KEY, 'true')

    setImpersonating(true)
    setToken(token)
  }

  /**
   * Determinates if we have a stored token or not
   */
  function hasToken() {
    return !!localStorage.getItem(LS_AUTH_KEY)
  }

  /**
   * Determinates if we're in impersonate mode or not
   */
  function isImpersonating(): boolean {
    return localStorage.getItem(LS_IMPERSONATE_KEY)
      && localStorage.getItem(LS_IMPERSONATE_KEY) === 'true' ? true : false
  }

  function exitImpersonating() {
    const originalToken = localStorage.getItem(LS_ORIGINAL_AUTH_KEY)

    if (!originalToken) {
      throw new Error('Could not exit impersonation, since there is no original token')
    }

    localStorage.setItem(LS_AUTH_KEY, originalToken)
    localStorage.removeItem(LS_ORIGINAL_AUTH_KEY)
    localStorage.removeItem(LS_IMPERSONATE_KEY)

    setImpersonating(false)
  }

  useEffect(() => {
    const currentCompanyId = user?.companies.find((company) => company.default === 1)?.id;
    dispatch(setCurrentCompanyId(currentCompanyId));
  }, [user?.companies])

  /**
   * Method to authenticate
   */
  async function authenticate() {
    try {
      setUser(await fetchUser())
      } catch (e) {
      setUser(null)
    }
  }

  return (
    <AuthContext.Provider value={{ user, authenticate, setToken, setImpersonation, exitImpersonating, impersonating, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
