import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export enum Type {
  DomesticBank = 'DOMESTIC_BANK',
  Bankgiro = 'BANKGIRO',
  Plusgiro = 'PLUSGIRO',
}

export interface Entity {
  accountType: Type
  accountNumber: string
  bank: Bank | null | undefined
}

export enum Bank {
  Alandsbanken = 'ALANDSBANKEN',
  AMFA = 'AMFA',
  Avanza = 'AVANZA',
  Citibank = 'CITIBANK',
  Collector = 'COLLECTOR',
  Danske_bank = 'DANSKE_BANK',
  DNB = 'DNB',
  Forex = 'FOREX',
  GE_money = 'GE_MONEY',
  Handelsbanken = 'HANDELSBANKEN',
  Icabanken = 'ICABANKEN',
  Ikano = 'IKANO',
  Landshypotek = 'LANDSHYPOTEK',
  Lansforsakringar = 'LANSFORSAKRINGAR',
  Lantmannen_finans = 'LANTMANNEN_FINANS',
  Marginalen_bank = 'MARGINALEN_BANK',
  Nordea = 'NORDEA',
  Nordnet = 'NORDNET',
  Other = 'OTHER',
  Resurs_bank = 'RESURS_BANK',
  Santande = 'SANTANDE',
  SEB = 'SEB',
  Skandia = 'SKANDIA',
  Sparbanken = 'SPARBANKEN',
  Swedbank = 'SWEDBANK',
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/settings/bank-account')
}

export async function patch(payload: BaseExchange<Entity>): Promise<BaseResponse<Entity>> {
  const response = await API.patch('internal/v1/company/settings/bank-account', payload)
  return response;
}
