import classNames from 'classnames'
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react'
import { HasClassName } from '@monto/react-common-ui'

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement>, HasClassName {
  innerRef?: Ref<HTMLInputElement>
}

const SwitchElement: React.FC<SwitchProps> = ({ className, innerRef, ...rest }) => {
  return (
    <div className="relative">
      <input
        className={classNames(
          'w-11 h-6 appearance-none bg-gray-300 border-2 border-gray-400 rounded-large focus:ring-4 focus:ring-primary-50 checked:bg-none checked:bg-primary-500 checked:hover:bg-primary-500 checked:focus:bg-primary-500 peer',
          {
            'disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:border-gray-200': rest.disabled,
            'cursor-pointer': !rest.disabled
          },
          className
        )}
        type="checkbox"
        ref={innerRef}
        {...rest}
      />
      <div className={classNames(
        'w-5 h-5 absolute left-0.5 top-0.5 rounded-large cursor-pointer bg-gray-50 duration-100 pointer-events-none peer-checked:border-primary-600 peer-checked:bg-white peer-checked:translate-x-full',
        { 'peer-disabled:translate-x-0 peer-disabled:bg-gray-100 peer-disabled:border-gray-200': rest.disabled }
      )} />
    </div>
  )
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(props, ref) {
  return (
    <SwitchElement {...props} innerRef={ref} />
  )
})
