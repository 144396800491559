import { forwardRef, FunctionComponent, InputHTMLAttributes, KeyboardEvent, Ref, useRef, useState } from 'react'
import { HasClassName } from '@monto/react-common-ui'
import { Loader } from '@monto/react-common-ui'
import { CloudUploadIcon } from '@monto/react-common-ui'
export interface FormFileProps extends HasClassName, InputHTMLAttributes<HTMLInputElement> {
  otherRef?: Ref<HTMLInputElement>
  loading: boolean
  text: string
  fileTypes?: string[] // .pdf, .jpg and so on
}

const FileElement: FunctionComponent<FormFileProps> = ({ className, loading, text, fileTypes, otherRef, ...rest }) => {
  const [focus, setFocus] = useState<boolean>(false)

  // Always call useRef
  const inputRef = useRef<HTMLInputElement>(null);
  const areaRef = useRef<HTMLLabelElement>(null);

  // Update the logic when using input and area
  const input = typeof window !== 'undefined' ? (otherRef || inputRef) : null;
  const area = typeof window !== 'undefined' ? areaRef : null;

  function handleKeyUp(event: KeyboardEvent<HTMLButtonElement>) {
    if (event.key === 'Enter' && area !== null) {
      area.current?.click()
    }
  }

  function acceptString(array: string[] | undefined) {
    if (array) {
      for (let x = 0; x < array.length; x++) {
        array[x] = '.' + array[x]
      }

      return array.join(',')
    }
  }

  return (
    <button
      className={`border border-primary-400 bg-gray-25 h-28 block w-full rounded-small hover:bg-gray-50 ${focus ? 'outline-none ring-4 ring-primary-50' : ''} ${className}`}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onKeyUp={handleKeyUp}
      type="button"
    >
      <label
        className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
        ref={area}
      >
        {
          loading
            ? (
              <Loader color="primary" className="scale-150" />
            ) : (
              <div className="font-bold text-primary-400">
                <CloudUploadIcon className="w-8 h-8 mx-auto mb-2" />
                <span>{text}</span>
              </div>
            )
        }

        <input type="file" ref={input} className="hidden" accept={acceptString(fileTypes)} {...rest} />
      </label>
    </button>
  )
}

export const File = forwardRef<HTMLInputElement, FormFileProps>(function Autocomplete(props, ref) {
  return (
    <FileElement otherRef={ref} {...props} />
  )
})

