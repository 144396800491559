import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch } from 'sdk/internal/v1/company/settings/autogiro';
import { Entity as AutogiroEntity } from 'sdk/internal/v1/company/settings/autogiro';
import { BaseResponse, BaseExchange } from '~/sdk/shared';

export type AutogiroState = {
  data: AutogiroEntity | null;
  loading: boolean;
  error: string | undefined;
};

const initialState: AutogiroState = {
  data: null,
  loading: false,
  error: undefined,
};

export const fetchAutogiro = createAsyncThunk(
  'autogiro/fetchAutogiro',
  async () => {
    try {
      const response: BaseResponse<AutogiroEntity> = await get();
      return response.data.data; // Access the actual data property inside the response data
    } catch (e) {

      throw new Error('Could not fetch autogiro');
    }
  }
);

export const patchAutogiro = createAsyncThunk<
  AutogiroEntity, // FulFilled
  AutogiroEntity, // Argument of the payload creator
  { rejectValue: unknown } // Rejected
>('autogiro/patchAutogiro', async (payload: AutogiroEntity) => {
  const baseExchangePayload: BaseExchange<AutogiroEntity> = {
    data: payload,
  };
  await patch(baseExchangePayload);
  return { account: payload.account }
});

const autogiroSlice = createSlice({
  name: 'autogiro',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutogiro.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchAutogiro.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchAutogiro.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(patchAutogiro.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(patchAutogiro.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(patchAutogiro.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default autogiroSlice.reducer;
