import { forwardRef, TextareaHTMLAttributes, Ref, createRef } from 'react'
import { FieldState } from '../Group/Group';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string
  otherRef?: Ref<HTMLTextAreaElement>
  state?: FieldState
}

const TextareaElement = ({ otherRef, state, className, ...props }: TextareaProps) => {
  const textareaRef = otherRef || createRef();

  return (
    <div className="input-wrapper">
      <textarea
        ref={textareaRef}
        id={props.name}
        data-state={state}
        {...props}
      />
    </div>
  )
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function textarea(props, ref) {
  return (
    <TextareaElement otherRef={ref} {...props} />
  )
});
