import { BaseExchange, BaseResponse, EmptyBaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export type Status = {
  status: 'OUTDATED' | 'NEED_CHECKUP' | 'VALID' | 'CHECKED_UP' | 'REJECTED' | 'NO'
}

export type Entity = {
  id?: string
  uid?: string
  firstName?: string
  lastName?: string
  hasSwedishSsn?: boolean
  swedishPersonalNumber?: string | null
  dateOfBirth?: string | null
  countryOfCitizenship?: string
  countryOfResidence?: string
  pep?: boolean
  pepExplanation?: string
}

export interface PostPayload {
  industry: string
  financingIntention: 'CONTINUING_OPERATIONS' | 'CONTINUING_EXPANSION' | 'OTHER'
  financingIntentionOther: string | null
  expectedAmountFrom: number,
  expectedAmountTo: number | null,
  financingRecurrence: 'LT_MONTHLY' | 'MONTHLY' | 'ONCE' | 'UNKNOWN'
  twentyFivePercentOwnership: string | boolean
  beneficialOwners: Entity[] | string | null
  alternativeBeneficialOwners: Entity[] | string | null
}

export interface GetResponse extends PostPayload {
  status: 'OUTDATED' | 'NEED_CHECKUP' | 'VALID' | 'CHECKED_UP' | 'REJECTED' | 'NO'
}

export async function get(): Promise<BaseResponse<GetResponse>> {
  return await API.get('internal/v2/company/kyc')
}

export async function post(payload: BaseExchange<PostPayload>): Promise<EmptyBaseResponse> {
  return await API.post('internal/v2/company/kyc', payload)
}
