import { createSlice, createAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductKeys, InvestmentTypeType } from '~/hooks/useOnboarding';
import { PaginatedBaseResponse, PaginatedLinks, Meta } from '~/sdk/shared';
import {
  Entity as ApplicationEntity,
  list as applicationList,
  remove,
  DeleteRequest,
  ListRequest,
} from '~/sdk/internal/v1/company/applications/';

export type AmountsType = {
  [key in ProductKeys]: number
}

interface DeleteApplicationParams {
  deleteRequest: DeleteRequest;
  appId: number;
}

export interface ApplicationState {
  data: ApplicationEntity[],
  meta: Meta,
  links: PaginatedLinks,
  currentCompanyId: number | undefined,
  useOnboarding: boolean,
  onboarding: {
    skipProvider: boolean,
    availableAmounts: AmountsType,
    desiredAmount: number,
    investmentType?: InvestmentTypeType,
    excluded?: ProductKeys,
  }
  status: 'loading' | 'ready' | 'init'
}

const initialState: ApplicationState = {
  status: 'init',
  data: [],
  useOnboarding: false,
  meta: {
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    filters: undefined
  },
  links: {
    self: '',
    first: '',
    prev: '',
    next: '',
    last: ''
  },
  currentCompanyId: undefined,
  onboarding: {
    skipProvider: false,
    excluded: undefined,
    investmentType: undefined,
    desiredAmount: 0,
    availableAmounts: {
      'si': 0,
      'bl': 0,
      'id': 0
    },
  }
};

// action to update the sync status
export const setCurrentCompanyId = createAction<number | undefined>('providers/setCurrentCompanyId');
export const updateAmount = createAction<AmountsType>('providers/updateAmount');
export const updateDesiredAmount = createAction<number>('providers/updateDesiredAmount');
export const setInvestmentType = createAction<InvestmentTypeType>('providers/setInvestmentType');
export const setExcluded = createAction<ProductKeys | undefined>('providers/setExcluded');
export const setSkipProvider = createAction<boolean>('providers/skipProvider');

// create async thunk
export const fetchApplications = createAsyncThunk(
  'applications/fetchApplications',
  async (req?: ListRequest) => {
    const { data: response } = await applicationList(req)
    return response;
  }
);

// Define an async thunk for removing an application
export const removeApplication = createAsyncThunk(
  'applications/removeApplication',
  async ({ deleteRequest, appId }: DeleteApplicationParams): Promise<number> => {
    await remove(deleteRequest);
    return appId;
  }
);

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentCompanyId, (state, action) => {
      state.currentCompanyId = action.payload;
    })
    builder.addCase(updateAmount, (state, action) => {
      state.onboarding.availableAmounts = { ...state.onboarding.availableAmounts, ...action.payload };
    })
    builder.addCase(updateDesiredAmount, (state, action) => {
      state.onboarding.desiredAmount = action.payload;
    })
    builder.addCase(setInvestmentType, (state, action) => {
      state.onboarding.investmentType = action.payload;
    })
    builder.addCase(setExcluded, (state, action) => {
      state.onboarding.excluded = action.payload;
    })
    builder.addCase(setSkipProvider, (state, action) => {
      state.onboarding.skipProvider = action.payload;
    })
    builder.addCase(fetchApplications.pending, (state) => {
      state.useOnboarding = false;
      state.status = 'loading';
    })
    builder.addCase(fetchApplications.rejected, (state) => {
      state.status = 'init';
    })
    builder.addCase(fetchApplications.fulfilled, (state, action: PayloadAction<PaginatedBaseResponse<ApplicationEntity>>) => {

      // update the applications state
      if (action.payload.data) {
        state.data = [...state.data, ...action.payload.data];
        state.meta = action.payload.meta;
        state.links = action.payload.links;
      }

      // set onboarding flag
      state.useOnboarding = action.payload.data.length === 0;

      state.status = 'ready';
    })
    builder.addCase(removeApplication.fulfilled, (state, action: PayloadAction<number>) => {

      // remove application from state
      const appId = action.payload;
      state.data = state.data.filter(app => app.id !== appId);
    });
  }
});

export default applicationSlice.reducer;
