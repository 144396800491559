import { configureStore } from '@reduxjs/toolkit';
import providersReducer from './providers/providersSlice';
import bankAccountReducer from './bankAccount/bankAccountSlice';
import guarantorsReducer from './guarantors/guarantorsSlice';
import businessLoanReducer from './businessLoan/businessLoanSlice';
import invoiceDiscountingReducer from './invoiceDiscounting/invoiceDiscountingSlice';
import meReducer from './me/meSlice';
import autogiroReducer from './autogiro/autogiroSlice';
import factoringReducer from './factoring/factoringSlice';
import applicationSubmitReducer from './applicationSubmit/applicationSubmitSlice';
import documentsReducer from './documents/documentsSlice';
import applicationReducer from './application/applicationSlice';

const store = configureStore({
  reducer: {
    providers: providersReducer,
    bankaccount: bankAccountReducer,
    guarantors: guarantorsReducer,
    businessloan: businessLoanReducer,
    me: meReducer,
    autogiro: autogiroReducer,
    factoring: factoringReducer,
    applicationsubmit: applicationSubmitReducer,
    invoicediscounting: invoiceDiscountingReducer,
    documents: documentsReducer,
    application: applicationReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

