import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { put as patch, get } from 'sdk/internal/v1/user/me';
import { Entity as MeEntity, Company as CompanyEntity } from 'sdk/internal/v1/user/me';
import { BaseResponse, BaseExchange } from '~/sdk/shared';

export type MeState = {
  data: MeEntity | null;
  currentCompany: CompanyEntity | undefined;
  loading: boolean;
  error: string | undefined;
  status: 'init' | 'ready'
};

const initialState: MeState = {
  data: null,
  currentCompany: undefined,
  loading: false,
  error: undefined,
  status: 'init'
};

export const fetchMe = createAsyncThunk(
  'me/fetchMe',
  async () => {
    const response: BaseResponse<MeEntity> = await get();

    return response.data.data; // Access the actual data property inside the response data
  }
);

export const patchMe = createAsyncThunk(
  'me/patchMe',
  async (payload: BaseExchange<Partial<MeEntity>>) => {
    const response: BaseResponse<MeEntity> = await patch(payload);
    return response.data.data;
  }
);

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMe.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;

        state.currentCompany = state.data.companies.find(c => c.default);
        state.status = 'ready';
      })
      .addCase(fetchMe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(patchMe.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(patchMe.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(patchMe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default meSlice.reducer;
