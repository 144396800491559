import { Money, PaginatedBaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'
import { AxiosResponse } from 'axios'

export enum TransactionType {
  InterestAdjustment = 'INTEREST_ADJUSTMENT',
  ForwardPayment = 'FORWARD_PAYMENT',
  Depreciation = 'DEPRECIATION',
  Adjustment = 'ADJUSTMENT',
  AdvanceOut = 'ADVANCE_OUT',
  OwedPayment = 'OWED_PAYMENT',
  CompoundedInterest = 'COMPOUNDED_INTEREST',
  CompoundedOverdueInterest = 'COMPOUNDED_OVERDUE_INTEREST',
  CompoundedFee = 'COMPOUNDED_FEE',
  OwedAdjustment = 'OWED_ADJUSTMENT',
  SellInvoiceFeeAmount = 'SELLINVOICE_FEE_AMOUNT',
  SellInvoiceFeePaidAmount = 'SELLINVOICE_FEE_PAID_AMOUNT',
  SellInvoiceInvoiceFeeAmount = 'SELLINVOICE_INVOICE_FEE_AMOUNT',
  SellInvoiceInvoiceFeePaidAmount = 'SELLINVOICE_INVOICE_FEE_PAID_AMOUNT',
  SellInvoiceTotalAmount = 'SELLINVOICE_TOTAL_AMOUNT',
  SellInvoicePaidAmount = 'SELLINVOICE_PAID_AMOUNT',
  TransferPayment = 'TRANSFER_PAYMENT',
  ClearanceAdjustment = 'CLEARANCE_ADJUSTMENT',
  RepaymentPayment = 'REPAYMENT_PAYMENT',
  OtherRevenuePayment = 'OTHER_REVENUE_PAYMENT',
  EscrowAdjustment = 'ESCROW_ADJUSTMENT',
  EscrowAdjustmentClosed = 'ESCROW_ADJUSTMENT_CLOSED',
  SellInvoiceOverdueInterestAmount = 'SELLINVOICE_OVERDUE_INTEREST_AMOUNT',
  SellInvoiceAdditionalFeeAmount = 'SELLINVOICE_ADDITIONAL_FEE_AMOUNT',
  AutogiroPayment = 'AUTOGIRO_PAYMENT',
  ManualPayment = 'MANUAL_PAYMENT',
  CompoundedInterestForPayment = 'COMPOUNDED_INTEREST_FOR_PAYMENT',
  CompoundedOverdueInterestForPayment = 'COMPOUNDED_OVERDUE_INTEREST_FOR_PAYMENT',
  CompoundedFeeForPayment = 'COMPOUNDED_FEE_FOR_PAYMENT',
  SellInvoiceTotalAmountAdjustment = 'SELLINVOICE_TOTAL_AMOUNT_ADJUSTMENT',
  SellInvoiceAdditionalFeeAmountWriteOff = 'SELLINVOICE_ADDITIONAL_FEE_AMOUNT_WRITE_OFF',
  SellInvoiceOverdueInterestAmountWriteOff = 'SELLINVOICE_OVERDUE_INTEREST_AMOUNT_WRITE_OFF',
  CustomerLosses = 'CUSTOMER_LOSSES',
  UnidentifiedPayment = 'UNIDENTIFIED',
  Payment = 'PAYMENT'
}

export enum TransactionStatus {
  BusinessLoan = 'BUSINESS_LOAN',
  Factoring = 'FACTORING',
  Clearance = 'CLEARANCE',
  Canceled = 'CANCELED',
  Normal = 'NORMAL',
  Escrow = 'ESCROW'
}

export interface Transaction {
  status: TransactionStatus,
  messageToClient?: string,
  type: TransactionType,
  advancementId: number,
  invoices: TransactionInvoice[],
  createdAt: string,
  interest: Money,
  balance: Money,
  amount: Money,
  id: number,
}

export type TransactionInvoice = {
  id: number,
  number: string
}

export interface Req {
  page?: number,
  size?: number
}

export interface Meta {
  currentPage: number;
  totalPages: number;
  pageSize: number;
}

export async function get(req?: Req): Promise<AxiosResponse<PaginatedBaseResponse<Transaction>>> {
  return await API.get(`internal/v2/company/transactions?size=${req?.size || 25}&page=${req?.page || 1}`)
}
