import React, { useState, useEffect, forwardRef, Ref, createRef } from 'react';

import { Input, InputProps } from '../Input/Input';

interface AmountProps extends  Omit<InputProps, 'onChange'> {
  currency?: 'SEK' | 'NOK' | 'EUR';
  otherRef?: Ref<HTMLInputElement>
  onChange?: (value: number) => void;
}

const AmountElement: React.FC<AmountProps> = ({
  currency = 'SEK',
  onChange,
  otherRef,
  value,
  ...props
}) => {

  const [inputValue, setInputValue] = useState<string | number>(value?.toLocaleString() || '');
  const inputRef = otherRef || createRef();

  useEffect(() => {
    setInputValue(value?.toLocaleString() || '');
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value.replace(/,/g, '')) || 0;
    setInputValue(e.target.value);
    onChange && onChange(newValue);
  }

  return (
    <Input
      {...props}
      value={inputValue}
      prepend={currency}
      onChange={handleChange}
      ref={inputRef}
    />
  )
}

const Amount = forwardRef<HTMLInputElement, AmountProps>(function Amount(props, ref) {
  return (
    <AmountElement otherRef={ref} {...props} />
  )
});

export default Amount;
