import classNames from 'classnames'
import React, { forwardRef, InputHTMLAttributes, Ref } from 'react'

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  innerRef?: Ref<HTMLInputElement>
  className?: string
}

const CheckboxElement: React.FC<CheckboxProps> = ({ className, innerRef, onChange, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event)
  }

  return (
    <input
      className={classNames(
        'w-6 h-6  rounded-small text-primary-500 checked:border-primary-500 focus:ring-4 focus:ring-primary-50',
        { 'checked:text-gray-700 checked:border-gray-200': rest.readOnly },
        className
      )}
      type="checkbox"
      ref={innerRef}
      onChange={handleChange}
      {...rest}
    />
  )
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(props, ref) {
  return (
    <CheckboxElement {...props} innerRef={ref} />
  )
})
