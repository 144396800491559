import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'sdk/internal/v2/company/products/business-loan';
import { Entity as BusinessLoanEntity } from 'sdk/internal/v2/company/products/business-loan';
import { BaseResponse, Money } from '~/sdk/shared';
import { RequirementCondition } from 'sdk/internal/v2/company/products';
import { BusinessLoanType } from '~/sdk/internal/v2/company/products/business-loan';


function getAvailableAmount(businessLoan?: BusinessLoanEntity) {

  const infiniteAmount = { amount: Infinity, currency: 'SEK' };

  if (!businessLoan) return infiniteAmount;

  const isBeforeApplication = businessLoan.requirements.filter(({ when }) => when === RequirementCondition.BeforeApplication).length > 0;
  if (isBeforeApplication) return infiniteAmount;

  return businessLoan.availableAmount ?? { amount: 0, currency: 'SEK' };
}

export type BusinessLoanState = {
  data: BusinessLoanEntity | null;
  loading: boolean;
  error: string | undefined;
  availableAmount: Money
  isFeeBased: boolean
  hasLoan: boolean
  minimumLoanAmount: number
};

const initialState: BusinessLoanState = {
  data: null,
  loading: false,
  error: undefined,
  availableAmount: getAvailableAmount(),
  isFeeBased: false,
  hasLoan: false,
  minimumLoanAmount: 1000
};

export const fetchBusinessLoan = createAsyncThunk(
  'businessloan/fetchBusinessLoan',
  async () => {
    const response: BaseResponse<BusinessLoanEntity> = await get();
    return response.data.data;
  }
);

const businessLoanSlice = createSlice({
  name: 'businessloan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessLoan.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchBusinessLoan.fulfilled, (state, action) => {

        // update states
        state.data = action.payload;
        state.availableAmount = getAvailableAmount(action.payload);
        state.hasLoan = action.payload.owedAmount.amount > 0;
        state.isFeeBased = action.payload.amortizationMethod === BusinessLoanType.Fee;
        state.loading = false;

      })
      .addCase(fetchBusinessLoan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export default businessLoanSlice.reducer;
